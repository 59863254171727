"use strict";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var FC2 =
/** @class */
function () {
  /**
   * Constructor for FC2 class
   */
  function FC2() {
    this.events = {};
    this.config = {
      readMoreLink: '',
      readMoreLabel: '',
      banner: {
        title: '',
        description: '',
        acceptAllLabel: '',
        customizeLabel: ''
      },
      modal: {
        title: '',
        description: '',
        acceptAllLabel: '',
        submitLabel: ''
      },
      sections: []
    };
    this.acceptKey = 'accept';
    this.declineKey = 'decline';
    this.bannerId = 'fc2-banner';
    this.modalId = 'fc2-modal';
    this.toggleClass = 'fc2-js-toggle';
    this.checkboxClass = 'fc2-js-checkbox';
    this.cookieName = 'fc2';
    this.cookieExpiresDays = 30;
    this.bannerInjected = false;
    this.modalInjected = false;
    this.state = {};
  }
  /**
   * Listen for a event called `eventName` and execute the `fn` callback function
   */


  FC2.prototype.on = function (eventName, fn) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(fn);
  };
  /**
   * Emit the event called `eventName` with optional `data`.
   * This calls every `fn` callback function registered
   * for event `eventName`
   */


  FC2.prototype.emit = function (eventName, data) {
    if (data === void 0) {
      data = {};
    }

    var event = this.events[eventName];

    if (event) {
      event.forEach(function (fn) {
        fn.call(null, data);
      });
    }
  };
  /**
   * Execute the `fn` callback function when the service matching `serviceKey` is Accepted
   */


  FC2.prototype.onAccept = function (serviceKey, fn) {
    this.on(serviceKey + ":" + this.acceptKey, fn);
  };
  /**
   * Execute the `fn` callback function when the service matching `serviceKey` is Declined
   */


  FC2.prototype.onDecline = function (serviceKey, fn) {
    this.on(serviceKey + ":" + this.declineKey, fn);
  };
  /**
   * Emit the Accept event for the service matching `serviceKey`
   */


  FC2.prototype.emitAccept = function (serviceKey) {
    var data = this.getService(serviceKey);
    this.emit(serviceKey + ":" + this.acceptKey, data);
  };
  /**
   * Emit the Decline event for the service matching `serviceKey`
   */


  FC2.prototype.emitDecline = function (serviceKey) {
    var data = this.getService(serviceKey);
    this.emit(serviceKey + ":" + this.declineKey, data);
  };
  /**
   * Emit all Decline and Accept events from the state's values
   */


  FC2.prototype.emitEventsFromState = function () {
    var _this = this;

    Object.keys(this.state).forEach(function (key) {
      if (_this.state[key]) {
        _this.emitAccept(key);
      } else {
        _this.emitDecline(key);
      }
    });
  };
  /**
   * Retrieve info for service matching serviceKey
   */


  FC2.prototype.getService = function (serviceKey) {
    var result = {};
    this.config.sections.forEach(function (section) {
      section.services.forEach(function (service) {
        if (service.key === serviceKey) {
          result = service;
        }
      });
    });
    return result;
  };
  /**
   * load script and stylesheet utils
   */


  FC2.loadScript = function (url, async) {
    if (async === void 0) {
      async = true;
    }

    var head = document.head;
    var script = document.createElement('script');
    script.async = async;
    script.src = url;
    head.appendChild(script);
  };
  /**
   * Init FC2 with config
   */


  FC2.prototype.init = function (config) {
    var _this = this;

    this.config = _extends(this.config, config);
    window.addEventListener('load', function () {
      _this.setStateFromCookie();

      if (!_this.wasSubmited()) {
        _this.showBanner();
      }
    });
  };
  /**
   * Convert an HTML string into a ChildNode
   */


  FC2.htmlToChildNode = function (html) {
    var template = document.createElement('template');
    var cleanedHtml = html.trim();
    template.innerHTML = cleanedHtml;

    if (template.content) {
      return template.content.firstChild;
    } else {
      return template.firstChild;
    }
  };
  /**
   * Inject Banner into DOM
   */


  FC2.prototype.injectBanner = function () {
    var bannerElement = FC2.htmlToChildNode("\n      <div class=\"fc2-banner fc2-wrapper\" id=\"" + this.bannerId + "\">\n      <div class=\"fc2-header\">\n        <p class=\"fc2-title\">" + this.config.banner.title + "</p>\n      </div>\n      <div class=\"fc2-body\">\n        <p>" + this.config.banner.description + "</p>\n        <a class=\"fc2-link\" href=\"" + this.config.readMoreLink + "\">" + this.config.readMoreLabel + "</a>\n      </div>\n      <div class=\"fc2-footer\">\n        <button class=\"fc2-button-cancel\" onclick=\"fc2.showModal(); fc2.hideBanner();\">" + this.config.banner.customizeLabel + "</button>\n        <button class=\"fc2-button-valid\" onclick=\"fc2.acceptAll(); fc2.hideBanner();\">" + this.config.banner.acceptAllLabel + "</button>\n      </div>\n      </div>\n    ");

    if (bannerElement) {
      document.body.appendChild(bannerElement);
    }
  };
  /**
   * Get HTML string for services
   */


  FC2.prototype.getServicesHTML = function (services) {
    var _this = this;

    var servicesHTML = services.map(function (service) {
      var checked = _this.state[service.key] ? ' checked' : '';
      return "\n        <li class=\"fc2-collapse-item\">\n          <div class=\"fc2-collapse-header\">\n            <p class=\"fc2-title " + _this.toggleClass + "\"><span class=\"fc2-collapse-icon\"></span>" + service.title + "</p>\n            <div class=\"fc2-switch\">\n              <input type=\"checkbox\" name=\"" + service.key + "\"" + checked + " class=\"" + _this.checkboxClass + "\">\n              <div class=\"fc2-switch-label\"></div>\n            </div>\n          </div>\n          <div class=\"fc2-collapse-body\">\n            <p>" + service.description + "</p>\n          </div>\n        </li>\n      ";
    });
    return servicesHTML.join('\n');
  };
  /**
   * Get HTML string for sections
   */


  FC2.prototype.getSectionsHTML = function () {
    var _this = this;

    var sectionsHTML = this.config.sections.map(function (section) {
      var services = _this.getServicesHTML(section.services);

      return "\n        <li class=\"fc2-list-item\">\n          <div class=\"fc2-list-header\">\n            <p class=\"fc2-title\">" + section.title + "</p>\n          </div>\n          <div class=\"fc2-list-body\">\n            <ul class=\"fc2-collapse\">\n              " + services + "\n            </ul>\n          </div>\n        </li>\n      ";
    });
    return sectionsHTML.join('\n');
  };
  /**
   * Inject Modal into DOM
   */


  FC2.prototype.injectModal = function () {
    var modalElement = FC2.htmlToChildNode("\n      <div class=\"fc2-modal fc2-wrapper\" id=\"" + this.modalId + "\">\n        <div class=\"fc2-header\">\n          <p class=\"fc2-title\">" + this.config.modal.title + "</p>\n        </div>\n        <div class=\"fc2-body\">\n          <p>" + this.config.modal.description + "</p>\n        </div>\n        <div class=\"fc2-body\">\n          <ul class=\"fc2-list\">\n            " + this.getSectionsHTML() + "\n          </ul>\n          <a class=\"fc2-link\" href=\"" + this.config.readMoreLink + "\">" + this.config.readMoreLabel + "</a>\n        </div>\n        <div class=\"fc2-footer\">\n          <button class=\"fc2-button-cancel\" onclick=\"fc2.acceptAll(); fc2.hideModal(500);\">" + this.config.modal.acceptAllLabel + "</button>\n          <button class=\"fc2-button-valid\" onclick=\"fc2.submit(); fc2.hideModal(500);\">" + this.config.modal.submitLabel + "</button>\n        </div>\n      </div>\n    ");

    if (modalElement) {
      document.body.appendChild(modalElement);
    }

    this.bindItemToggle();
  };
  /**
   * Bind click on services to display/hide description
   */


  FC2.prototype.bindItemToggle = function () {
    var itemElements = document.getElementsByClassName(this.toggleClass); // we use a basic for loop insteand of Array.from to keep compatibility with old browsers

    for (var i = 0; i < itemElements.length; i++) {
      itemElements[i].addEventListener('click', function () {
        if (this.parentNode && this.parentNode.parentNode) {
          this.parentNode.parentNode.classList.toggle('active');
        }
      });
    }
  };
  /**
   * Return banner HTMLElement
   */


  FC2.prototype.getBannerElement = function () {
    return document.getElementById(this.bannerId);
  };
  /**
   * Return modal HTMLElement
   */


  FC2.prototype.getModalElement = function () {
    return document.getElementById(this.modalId);
  };
  /**
   * Show banner
   */


  FC2.prototype.showBanner = function () {
    if (!this.bannerInjected) {
      this.injectBanner();
      this.bannerInjected = true;
    }

    var bannerElement = this.getBannerElement();

    if (bannerElement) {
      bannerElement.classList.add('active');
    }
  };
  /**
   * Hide banner
   */


  FC2.prototype.hideBanner = function () {
    var bannerElement = this.getBannerElement();

    if (bannerElement) {
      bannerElement.classList.remove('active');
    }
  };
  /**
   * Show modal
   */


  FC2.prototype.showModal = function () {
    if (!this.modalInjected) {
      this.injectModal();
      this.modalInjected = true;
    }

    var modalElement = this.getModalElement();

    if (modalElement) {
      modalElement.classList.add('active');
    }
  };
  /**
   * Hide modal
   */


  FC2.prototype.hideModal = function (timeout) {
    var _this = this;

    if (timeout === void 0) {
      timeout = 0;
    }

    setTimeout(function () {
      var modalElement = _this.getModalElement();

      if (modalElement) {
        modalElement.classList.remove('active');
      }
    }, timeout);
  };
  /**
   * Set FC2 cookie with given value
   */


  FC2.prototype.setCookie = function (value) {
    if (value === void 0) {
      value = '';
    }

    var date = new Date();
    date.setTime(date.getTime() + this.cookieExpiresDays * 24 * 60 * 60 * 1000);
    var expires = date.toUTCString();
    document.cookie = this.cookieName + "=" + value + "; expires=" + expires + "; path=/";
  };
  /**
   * Delete FC2 cookie
   */


  FC2.prototype.deleteCookie = function () {
    var date = new Date();
    date.setTime(date.getTime() - 1 * 24 * 60 * 60 * 1000);
    var expires = date.toUTCString();
    document.cookie = this.cookieName + "=; expires=" + expires + "; path=/";
  };
  /**
   * Get FC2 cookie value
   */


  FC2.prototype.getCookie = function () {
    var name = this.cookieName + "=";
    var cookiesParts = document.cookie.split(';');
    var value = '';
    cookiesParts.forEach(function (cookiePart) {
      var cleanedCookiePart = cookiePart;

      while (cleanedCookiePart.charAt(0) === ' ') {
        cleanedCookiePart = cleanedCookiePart.substring(1, cookiePart.length);
      }

      if (cleanedCookiePart.indexOf(name) === 0) {
        value = cleanedCookiePart.substring(name.length, cleanedCookiePart.length);
      }
    });
    return value;
  };
  /**
   * Retrieve value from Cookie
   * and set it on the `state` property
   */


  FC2.prototype.setStateFromCookie = function () {
    var cookieValue = this.getCookie();

    if (cookieValue) {
      this.state = cookieValue.split('|').reduce(function (prev, current) {
        var arr = current.split('=');
        prev[arr[0]] = !!+arr[1];
        return prev;
      }, {});
      this.emitEventsFromState();
    }
  };
  /**
   * Set value from the `state` property
   * to the cookie
   * Value is stored with the following format
   * `FooServiceKey=1|BarServiceKey=0|BazServiceKey=1`
   */


  FC2.prototype.setCookieFromState = function () {
    var _this = this;

    var cookieValue = Object.keys(this.state).reduce(function (prev, key) {
      var val = _this.state[key] ? 1 : 0;
      prev.push(key + "=" + val);
      return prev;
    }, []).join('|');
    this.setCookie(cookieValue);
    this.emitEventsFromState();
  };
  /**
   * Return true is the modal was submitted by the user
   */


  FC2.prototype.wasSubmited = function () {
    return Object.keys(this.state).length > 0;
  };
  /**
   * Return true is the service matching serviceKey was accepted
   */


  FC2.prototype.isAccepted = function (serviceKey) {
    return this.state[serviceKey] || false;
  };
  /**
   * Return true is the service matching serviceKey was declined
   */


  FC2.prototype.isDeclined = function (serviceKey) {
    return !this.isAccepted(serviceKey);
  };
  /**
   * Accept all services
   */


  FC2.prototype.acceptAll = function () {
    var _this = this;

    var checkboxesElements = document.getElementsByClassName(this.checkboxClass);

    if (checkboxesElements.length > 0) {
      // we use a basic for loop insteand of Array.from to keep compatibility with old browsers
      for (var i = 0; i < checkboxesElements.length; i++) {
        var key = checkboxesElements[i].getAttribute('name');
        checkboxesElements[i].checked = true;

        if (key) {
          this.state[key] = true;
        }
      }
    } else {
      this.config.sections.forEach(function (section) {
        section.services.forEach(function (service) {
          _this.state[service.key] = true;
        });
      });
    }

    this.setCookieFromState();
  };
  /**
   * Submit choices in modal
   */


  FC2.prototype.submit = function (allTrue) {
    if (allTrue === void 0) {
      allTrue = false;
    }

    var checkboxesElements = document.getElementsByClassName(this.checkboxClass); // we use a basic for loop insteand of Array.from to keep compatibility with old browsers

    for (var i = 0; i < checkboxesElements.length; i++) {
      var key = checkboxesElements[i].getAttribute('name');
      var value = checkboxesElements[i].checked;

      if (key) {
        this.state[key] = value || allTrue;
      }
    }

    this.setCookieFromState();
  };

  return FC2;
}();

var fc2 = new FC2();